import { useQuery } from "react-query";
import getAPI from "../services/api";
import { DataConfig } from "shared/DataConfig";

type DataResponse = {
  context: DataConfig;
  survey: {
    surveyId: string;
    status: string;
    country: string;
    description: string;
    fileName: string;
    mappingId: string;
    name: string;
    surveyType: string;
    year: number;
    table_name: string;
    answerMaps: Record<string, Record<number, string>>;
    fields: Array<{
      category: string;
      descriptionInGroup: string | null;
      ignore: boolean;
      ingoredInfo: Record<string, string | null>;
      includeInFilter: boolean;
      includeInSplit: boolean;
      info: string | null;
      label: string;
      mappingId: string;
      metric: string;
      negativeFlag: boolean;
      outcome: string | null;
      questionCode: string;
      questionDescription: string | null;
      questionGroup: string | null;
      questionText: string | null;
      questionType: string | null;
      rankInSubCategory: string | null;
      ranked: boolean;
      subCategory: string | null;
      valInProportion: Array<string> | null;
      virtual: boolean;
      virtualConfig: null | {
        referToQuestionCode: string;
        categories: Array<
          | {
              name: string;
              from: number;
              to: number;
            }
          | {
              name: string;
              values: Array<string>;
            }
        >;
      };
    }>;
  };
  stats: [Record<string, Record<string, number>>];
};
export function useData(config: DataConfig) {
  return useQuery(
    ["data", JSON.stringify(config)],
    async () => {
      if (!config.questionId) {
        return null;
      }
      return getAPI().then((api) =>
        api.post<DataResponse>("/data", config).then((res) => {
          return res.data;
        }),
      );
    },
    { enabled: !!config.surveyId, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false, refetchInterval: false, retry: 1 },
  );
}
